<template>
  <div id="date-page">
    <TitleHelper :title="'Leaders on ' + (day < 10 ? '0' + day : day) + '/' + (month < 10 ? '0' + month : month) + '/' + year"></TitleHelper>

    <div class="select">
        <select v-model="day" @change="getDate()">
            <option v-for="option in dayOptions" :value="option.value" :key="option.value">
            {{ option.text }}
            </option>
        </select>
        <select v-model="month" @change="generateDayOptions(); getDate()">
            <option v-for="option in monthOptions" :value="option.value" :key="option.value">
            {{ option.text }}
            </option>
        </select>
        <select v-model="year" @change="getDate()">
            <option v-for="option in yearOptions" :value="option.value" :key="option.value">
            {{ option.text }}
            </option>
        </select>
    </div>
    <div>
        <button @click="incYear(); getDate()">+</button>
        <button @click="decYear(); getDate()">-</button>
    </div>
    <div class="spacer"></div>
    <div class="display">
        <div v-for="item in data" :key="item._id" style="margin-bottom: 5px;">
            <b>{{item.name}}</b> - {{item.title}}<br />
        </div>
    </div>
  </div>
</template>

<script>
const axios = require("axios");
const config = require("../../assets/config");
import TitleHelper from '../../helpers/vueTitleHelper.vue';

export default {
  name: "Date",
  beforeCreate: function() {
    document.body.className = 'date';
  },
  data() {
    return {
        values: config,
        data: [],
        errorValue: null,
        year: "2020",
        month: 4,
        day: 1,

        monthOptions: [
        { text: 'January', value: 1 },
        { text: 'February', value: 2 },
        { text: 'March', value: 3 },
        { text: 'April', value: 4 },
        { text: 'May', value: 5 },
        { text: 'June', value: 6 },
        { text: 'July', value: 7 },
        { text: 'August', value: 8 },
        { text: 'September', value: 9 },
        { text: 'October', value: 10 },
        { text: 'November', value: 11 },
        { text: 'December', value: 12 }
        ],
        monthLengths: [31,28,31,30,31,30,31,31,30,31,30,31],
        leapMonthLengths: [31,29,31,30,31,30,31,31,30,31,30,31],
        yearOptions:[],
        dayOptions:[],
    };
  },
  mounted() {
      var d = new Date();
      this.day = d.getDate();
      this.month = d.getMonth()+1;
      this.year = d.getFullYear().toString();
      if (this.$route.params.pathMatch && this.$route.params.pathMatch.split('-').length == 3) {
          let d = this.$route.params.pathMatch.split('-');
          this.year = d[0];
          this.month = parseInt(d[1]);
          this.day = parseInt(d[2]);
      }
      this.generateDayOptions();
      this.generateYearOptions();
      this.getDate();
  },
  components: {
    TitleHelper,
  },
  methods: {
    incYear: function() {
        var y = parseInt(this.year)
        var now = new Date();
        if (this.year.includes('BC')) {
            y -= 1;
            if (y == 0) {
                this.year = '1';
            } else {
                this.year = y.toString()+'BC';
            }
        } else if (y+1 > now.getFullYear()) {
            this.year = now.getFullYear().toString();
        } else {
            y += 1;
            this.year = y.toString();
        }
    },
    decYear: function() {
        var y = parseInt(this.year)
        if (this.year.includes('BC')) {
            y += 1;
            this.year = y.toString() + 'BC';
        } else {
            y -= 1;
            if (y == 0) {
                this.year = '1BC';
            } else {
                this.year = y.toString();
            }
        }
    },
    isYearPrime: function() {
        if (parseInt(this.year)%400 == 0) {
            return true;
        } else if (parseInt(this.year)%100 ==0) {
            return false;
        } else if (parseInt(this.year)%4 ==0) {
            return true;
        } else {
            return false;
        }
    },
    generateDayOptions: function() {
        var monthLength = 0;
        this.dayOptions = [];
        if (this.isYearPrime(this.Year)) {
            monthLength = this.leapMonthLengths[this.month-1];
        } else {
            monthLength = this.monthLengths[this.month-1];
        }
        for (var d = 1; d <= monthLength; d += 1) {
            this.dayOptions.push( { text: d , value: d } );
        }
    },
    generateYearOptions: function() {
        this.yearOptions = [];
        var label = ''
        var val = ''
        for (var y = 2020; y > -100; y -= 1) {
            if (y > 0) {
                label = y.toString()
                val = y.toString()
            } else {
                label = (Math.abs(y)+1).toString()+' BC'
                val = (Math.abs(y)+1).toString()+'BC'
            }
            this.yearOptions.push( { text: label, value: val })
        }
    },
    getDate: function() {

      var config = {
        headers: {
          "Content-Type": "application/json"
        }
      };
      var body = {  };
      var url = this.values.BACKEND_CONNECTION + "://" + this.values.BACKEND_SERVER + ":" + this.values.BACKEND_SERVER_PORT + "/api/mongo/leader/" + this.year + '-' + this.month + '-' + this.day;
      axios
        .get(url,body,config)
        .then(response => {
            this.data = response["data"];
        })
        .catch(error => {
            this.errorValue = error;
        })
      if (this.$route.fullPath != '/date/' + this.year + '-' + this.month.toString() + '-' + this.day.toString()) {
          this.$router.push({ path: '/date/' + this.year + '-' + this.month.toString() + '-' + this.day.toString() })
      }
    }
  },
  computed: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

@font-face {
    font-family: 'pt_sans';
    src: url('ptsans-bold-webfont.woff2') format('woff2'),
         url('ptsans-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;

}

@font-face {
    font-family: 'pt_sans';
    src: url('ptsans-regular-webfont.woff2') format('woff2'),
         url('ptsans-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

div.display div:nth-child(even) {
    background-color: rgba(248, 214, 163, 0.2)
}

#date-page {
  padding: 8px;
  min-height: calc(100vh - 16px);
  background-color: #eaead0;
  font-family: "pt_sans", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}


body {
  margin: 0px;
}

button {
  background-color: teal;
  color: white;
  border: 0px;
  width: 25%;
  height: 30px;
  border-radius: 3px;
  margin: 10px;
}

button[disabled] {
  background-color: #999999;
}

button[disabled]:hover {
  background-color: inherit;
  box-shadow: inherit;
}

button:hover {
  background-color: #2bb7c4;
  box-shadow: 3px 3px 2px 0px #cccccc;
}

.tab-button {
  border-radius: 3px 3px 0px 0px;
  padding: 6px 10px;
  cursor: pointer;
  background: #f0f0f0;
  color: black;
  margin: 0px 4px 0px 4px;
  width: 15%;
}

.tab-button:hover {
  background: #e0e0e0;
  box-shadow: none;
}
.tab-button.active {
  background: teal;
  color: white;
  box-shadow: none;
}

.tab {
  border-top: 1px solid #cccccc;
  height: calc(100vh - 91px);
}

button:focus {
  outline:0;
}

select {
  width: 25%;
  height: 30px;
  margin: 10px;
}

div.spacer {
  height:30px;
}
div.error {
  margin: 20px;
  padding: 10px;
  background-color: #ff9999;
  border: 2px red;
}
</style>
